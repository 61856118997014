.product-family-hero {
    height: 160px;
    min-height: 160px;
    max-height: 160px;
    /* background-color: #011f3d; */
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.product-family-hero .title-container {
    flex: 1;
    padding-left: min(70px, 10vw);
    color: white;
}

.product-family-hero .title-container h2 {
    color: white !important;
}

.product-family-hero .title-container h1 {
    font-size: 32px;
    font-weight: 40px;
    font-weight: bolder;
    color: white;
}


.product-family-hero .image-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: right;
    overflow: clip;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.product-family-hero .image-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

/* Styling for product list area */

.product-family-content {
    /* min-height: calc(100vh - 224px); */
    height: max-content;
    padding: 24px min(70px, 5vw) 40px;
}

/* breadcrumbs styling */

.breadcrumbs {
    padding: 12px 0px;
}

.breadcrumbs ul {
    list-style: none;
}

.breadcrumbs ul li {
    display: inline;
    margin-bottom: 0.57rem;
}

.breadcrumbs ul li a,
.breadcrumbs ul li span {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}

.breadcrumbs ul li a {
    color: #058192;
    text-decoration: none;
}

.breadcrumbs ul li a:hover {
    color: #058192;
    cursor: pointer;
    text-decoration: underline;
}

.breadcrumbs ul li span {
    color: #36597d;
}

.breadcrumbs ul li i {
    color: #36597d;
    padding: 0 .125rem;
}

/* container styling */

.product-family-content .container {
    padding: 0px 0px 16px;
    display: flex;
    width: 100%;
    /* margin: auto; */
    column-gap: 18px;
    position: relative;
}

/* left filter container styling */
.left-filter {
    height: max-content;
    width: 300px;
    position: sticky;
    top: 0px;
    /* padding-top: 16px; */
    padding-top: 24px;
}

/* Emphasis title styling */

.emphasis-title {
    /* line-height: 20px; */
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 14px;
    margin-top: 20px;
}

/* toc styling */
.toc {
    height: calc(100vh - 184px);
}

span.toc-item {
    display: block;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    padding: 10px 4px 10px 16px;
    border-left: solid #F0F2F4 4px;
    width: 100%;
    transition: background-color 150ms ease-in-out, font-weight 150ms ease-in-out;
    color: #36597D;
}

span.toc-item:hover {
    cursor: pointer;
    color: #011f3d;
    /* font-weight: 600; */
    outline: solid #058192 2px;
    outline-offset: -2px;
}

span.toc-item.active {
    color: #011f3d;
    font-weight: 600;
    /* border-left-color: #36597D; */
    border-left-color: #058192;
    background-color: #F0F2F4;
}

/* search and quickfilter styling */

.quickfilter-search-container {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 1;
    padding: 12px 0px;
    padding-top: 32px;
}

.quickfilter-search-container #title {
    display: inline-block;
    margin-right: 4px;
    font-weight: 700;
    font-size: 22px;
}


.quickfilter-search-container span#results-count {
    font-weight: 700;
    /* color: #36597D; */
}

.search-input-container {
    display: flex;
    /* height: 32px; */
    min-width: 305px;
    width: 50vw;
    max-width: 320px;
    align-items: center;
    column-gap: 12px;
    border: solid rgb(54, 89, 125) 1px;
    /* padding: 4px 12px; */
    margin: 8px 0px;
}

.search-input-container input[type="text"] {
    border: none;
    outline: none;
    flex: 1;
}

.search-input-container #clear-btn {
    width: 24px;
    height: 24px;
    border: none;
    background-color: white;
}

.search-input-container #clear-btn:focus,
.search-input-container #clear-btn:hover {
    outline: solid teal 2px;
    outline-offset: -2px;
}

.search-input-container #clear-btn:hover {
    cursor: pointer;
}

.search-input-container #clear-btn:active {
    outline: solid teal 2px;
    outline-offset: -2px;
    background-color: #c5edea;
}

#product-guide-search:placeholder-shown~#clear-btn {
    display: none;
}

/* Quickfilter styling */

.dlt-tags .dlt-tags__item {
    margin-top: 6px;
    transition: all 150ms ease-in-out;
}

#quickfilter .dlt-tags__item i.fa-sharp.fa-solid.fa-plus {
    margin-left: 4px;
    transition: all 100ms linear;
    display: none;
}

/* .dlt-tags[data-allow-selection] .dlt-tags__item--selected i.fa-sharp.fa-solid.fa-check {
    margin-right: 4px;
} */

#quickfilter .dlt-tags__item.dlt-tags__item--selected i.fa-sharp.fa-solid.fa-plus {
    transform: rotateZ(45deg);
    display: inline-block;
}

/* products list styling */

.products-list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.products-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, 280px);
    row-gap: 20px;
    column-gap: 20px;
    margin: 10px;
    width: 888px;
}

.quickfilter-search-container {
    width: 888px;
}

/* Empty State styling */

.empty-state-container {
    display: none;
}

.products-list[count="0"] {
    display: flex;
    justify-content: center;
    /* padding-top: 20px; */
}

.products-list[count="0"] .empty-state-container {
    display: block;
}

/* Capabilities dropdown filter for smaller screens */

.dlt-form-item.categories-filter-sm  {
    min-width: 305px;
    width: 50vw;
    max-width: 320px;
    align-items: center;
    margin-top: 24px;
    display: none;
}

.categories-filter-sm .fa-caret-down:before {
    content: "\f0d7";
    font-size: 18px;
    margin-right: 4px;
}

.categories-filter-sm li.active-result.result-selected {
    background-color: var(--dlt-forms-color-background-interactive-secondary-default, var(--dlt-component-color-background-interactive-secondary-default, var(--dlt-color-background-interactive-secondary-default, #fff)));
    border: var(--dlt-border-width-default, 1px) var(--dlt-border-style-default, solid) var(--dlt-forms-color-interactive-primary-default, var(--dlt-component-color-interactive-primary-default, var(--dlt-color-background-interactive-primary-default, #1776bf)));
    box-shadow: inset 0 0 0 1px var(--dlt-forms-color-interactive-primary-default, var(--dlt-component-color-interactive-primary-default, var(--dlt-color-background-interactive-primary-default, #1776bf)));
    color: var(--dlt-forms-color-text-default, var(--dlt-component-color-text-default, var(--dlt-color-text-interactive-secondary, #333)));
    outline: none;
}

/* Responsive design */
@media only screen and (min-width: 1972px) {
    .products-list {
        width: 1185px;
    }

    .quickfilter-search-container {
        width: 1185px;
    }
}

@media only screen and (max-width: 1335px) {
    .products-list {
        width: 630px;
    }

    .quickfilter-search-container {
        width: 630px;
    }
}

@media only screen and (max-width: 997px) {
    .products-list {
        width: 360px;
    }

    .quickfilter-search-container {
        width: 360px;
    }
}

@media only screen and (max-width: 750px) {
    .products-list {
        width: 630px;
    }

    .quickfilter-search-container {
        width: 630px;
    }

    .left-filter {
        display: none;
    }

    /* .quickfilter-search-container h3 {
        display: none;
    } */

    .quickfilter-search-container {
        padding-top: 18px;
        padding-bottom: 0px;
    }

    .dlt-form-item.categories-filter-sm {
        display: block ;
    }

    /* #reset-prodfam-filter-btn {
        display: none;
    } */
    .products-list[count="0"] .empty-state-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

}

@media only screen and (max-width: 704px) {
    .products-list {
        width: 360px;
        justify-content: center;
    }

    .quickfilter-search-container {
        width: 360px;
    }

    .products-list-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 450px) {
    .products-list {
        width: 305px;
    }

    .quickfilter-search-container {
        width: 315px;
    }
}


.mCSB_scrollTools {
    display: none !important;
}