.product-card {
    padding: 4px 12px 12px 12px;
    border: solid #e1e4e7  1px;
    display: inline-flex;
    flex-direction: column;
    width: 290px;
    height: 144px;
    position: relative;
}

.product-card:focus,
.product-card:focus-visible,
.product-card:hover {
    box-shadow: 0px 2px 12px 2px rgba(1, 31, 61, 0.12);
    cursor: -moz-pointer;
    cursor: -webkit-pointer;
    cursor: pointer;
    transition: box-shadow 150ms linear;
    outline: transparent;
}

.product-card::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 0;
    height: 2px;
    border-top: 2px;
    background: linear-gradient(99.14deg, #8e3cf7 6.93%, #3cc1b6 93.07%);
    -webkit-transition: width .2s;
    transition: width .2s;
    /* display: block; */
}

.product-card:focus::before,
.product-card:focus-visible::before,
.product-card:hover::before {
    width: 100%;
}

.product-card[filter-valid="false"] {
    display: none !important;
}

.product-card[search-valid="false"] {
    display: none !important;
}

.product-card[filter-valid="true"][search-valid="true"] {
    display: inline-flex !important;
}


.product-title {
    color: #011f3d;
    font-weight: 600;
    padding: 2px 0px 8px;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    flex: 1;
}

.product-tags-container {
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
    column-gap: 4px;
}

.product-tag {
    width: max-content;
    font-size: 10px;
    line-height: 12px;
    background-color: #F0F2F4;
    padding: 4px 6px;
}
.system-req
{
    margin-left: auto;
}

.recently-updated-container {
    display: flex;
    justify-content: end;
    height: 26px;
    margin-right: -7px;
}

/* .recently-updated-container .recent-tag {
    height: 24px;
    width: 80px;
    font-size: 12px;
    position: relative;
    background-color: white;
    border-radius: 60px;
    padding: 4px;
    color: #8E3CF7;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.recently-updated-container .recent-tag::before {
    content: '';
    width: 125px;
    height: 30px;
    display: inline-block;
    position: absolute;
    top: -3px;
    left: -3px;
    z-index: -1;
    background: linear-gradient(to right,
            #8E3CF7 0%,
            #8E3CF7 20%,
            #7D58E9 33%,
            #5A91CE 61%,
            #44B4BD 82%,
            #3CC1B6 93%);
    border-radius: 60px;
} */

.recently-updated-container .recent-tag {
    background: linear-gradient(to right, #8E3CF7 0%, #8E3CF7 20%, #7D58E9 33%, #5A91CE 61%, #44B4BD 82%, #3CC1B6 93%);
    width: max-content;
    padding: 4px 2px 4px 2px;
    font-size: 12px;
    border-radius: 40px;
    transform: scale(0.7);
    margin-right: -16px;
}

.recently-updated-container .recent-tag span {
    background-color: white;
    padding: 4px;
    border-radius: 64px;
    color: #8E3CF7;
    font-weight: 600;
}