/* Nav bar element styling */
/* Styling for elements in '_root-nav.html.twig' partial */

#master-nav {
    /* position: sticky; */
    /* z-index: var(--dlt-z-index-mask, 100); */
    /* position: revert; */
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
    column-gap: 16px;
    height: 64px;
}

.product-logo {
    width: min-content !important;
}

.utility-wrapper {
    flex: 1;
    display: flex;
    justify-content: right;
}

#gcse-searchbox input::placeholder {
    font-style: italic;
    color: #7186A4;
    opacity: 1;
}
.dlt-form-item.dlt-search.dlt-search--sync.global-search {
    min-width: 120px;
    width: 100%;
    max-width: 400px;
}

/* App Switcher Styling */


/* #app-switcher {
    height: 530px;
    overflow: auto;
}

#app-switcher-btn a {
    height: 64px;

    display: -moz-inline-flex;
    display: -webkit-inline-box;
    display: -ms-inline-flex;
    display: inline-flex;

    -moz-justify-content: center;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
}

#app-switcher-btn a {
    margin: 0;
}

.dlt-app-switcher-draw .apps-list,
.apps-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: var(--dlt-spacing-2-x, 1rem);
    grid-row-gap: var(--dlt-spacing-2-x, 1rem);
}

.dlt-app-switcher-draw .apps-list+.apps-list h6,
.apps-list h6 {
    grid-column: 1/4;
    color: var(--dlt-app-switchers-color-text-default, var(--dlt-component-color-text-default, var(--dlt-color-text-interactive-secondary, #333)));

    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;

    -moz-flex: 0 100%;
    -webkit-flex: 0 100%;
    -ms-flex: 0 100%;
    flex: 0 100%;

    font-size: var(--dlt-font-size-xs, .875rem);
    font-weight: var(--dlt-font-weight-medium, 500);
    margin-bottom: 1rem;
    margin-top: 0;
    margin-bottom: 0;
}

.dlt-app-switcher-draw .apps-list+.apps-list::before {
    grid-column: 1/4;
} */

/* hiding nav elements for smaller screens */
/* @media only screen and (max-width: 670px) {
    .dlt-horizontal-nav .main-level a {
        display: none;
    }
} */

.gsc-webResult.gsc-result {
    padding-top: 0px;
}

.gsc-webResult.gsc-result:hover {
    border-color: transparent !important;
    background-color: #FFFFFF;
}