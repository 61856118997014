/* single card styling */
 
.pf-card {
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
 
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
 
    min-height: 180px;
    /*height: max-content;*/
    max-width: 400px;
    min-width: 300px;
    padding: 25px;
 
    outline: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    border: 0.6px solid #EFF0F1;
    border-radius: var(--dlt-border-radii-default, 0);
    position: relative;
}
 
.pf-card:hover {
   
    box-shadow: 0px 2px 12px 2px rgba(1, 31, 61, 0.12); /* Horizontal offset, vertical offset, blur radius, color */
    /* border: var(--dlt-border-width-hover, 2px) var(--dlt-border-style-hover, solid) transparent;
    -webkit-box-shadow: var(--dlt-elevation-md, 0 2px 12px 2px rgba(0, 0, 0, .12));
    -moz-box-shadow: var(--dlt-elevation-md, 0 2px 12px 2px rgba(0, 0, 0, .12));
    box-shadow: var(--dlt-elevation-md, 0 2px 12px 2px rgba(0, 0, 0, .12)); */
    cursor: pointer;
    cursor: -moz-pointer;
    cursor: -webkit-pointer;
}
 
.pf-card:focus {
    border: var(--dlt-border-width-hover, 2px) var(--dlt-border-style-hover, solid) var(--dlt-cards-color-interactive-primary-default, var(--dlt-component-color-interactive-primary-default, var(--dlt-color-background-interactive-primary-default, #1776bf)));
}

.pf-card::before {
	content: "";
	position: absolute;
	top: -2px;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	width: 0;
	height: 2px;
	border-top: 2px;
	background: linear-gradient(99.14deg,#8e3cf7 6.93%,#3cc1b6 93.07%);
	-webkit-transition: width .2s;
	transition: width .2s;
	/* display: block; */
}

.pf-card:hover::before{
    width: 100%;
}

.pf-card-body {
    display: flex;
    align-items: flex-start; 
    height: 100%;
}
 
 
.pf-card-header {
    display: flex;
    align-items: center;
}
 
.pf-card-icon {
    flex: 0 0 auto; 
    margin-right: 20px; 
}
 
.pf-card-details {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    min-height: 128px;
    flex: 1; 
}

.pf-card-actions {
    align-self: start; 
    font-weight: 700;
}

.pf-card-actions a{
    padding: 2px;
}

.pf-card-actions a:visited{
    color: var(--dlt-color-text-interactive-link-default,#1776bf);
    text-decoration: none;
}

.pf-card-actions a:focus{
    text-decoration: underline;
    outline: solid teal 2px;
}

.pf-card-subtitle {
    margin-bottom: 6px;
    color: #36597D;
    font-weight: 700;
}
.pf-card-title{
    color: #011F3D;
    font-weight: 700;
}
.pf-card-content {
    -moz-flex: 1 0;
    -webkit-flex: 1 0;
    -ms-flex: 1 0;
    flex: 1 0;
    margin-bottom: 10px;
}