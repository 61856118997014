#landing-banner-container {
    position: relative;
    width: 100vw;
    height: 335px;
    min-height: 335px;
    max-height: 335px;
    overflow: hidden;
    background-color: #011f3d;
}

#landing-header-container {
    background-color: transparent;
    min-width: 350px;
    width: 90vw;
    max-width: 720px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);

    display: -moz-flex;
    display: -webkit-box;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: clip;
    color: white;
    text-align: left;
}

#landing-header h1 {
    color: white;
}

#landing-search-container {
    max-width: 545px;
    min-width: 305px;
    width: 70vw;
    height: 42px;
    box-shadow: 0px 0px 0px 120px rgba(2, 31, 61, 0.8);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #e8ecfc;
    border: solid #8f71a4 1px;
    padding: 4px;
}

#landing-search-container:has(input:focus),
#landing-search-container:has(input:active) {
    outline: solid #8E3CF7 2px;
}

#landing-search-container input {
    width: calc(100% - 24px);
    height: 32px;
    font-size: 16px;
    border: none;
    outline: none;
    /* background-color: #E8FCFB; */
    background-color: #e8ecfc;
}

#landing-search-container button.search-btn {
    height: 24px;
    width: 24px;
    border: none;
    outline: none;
    font-size: 18px;
    background-color: transparent;
}

#landing-search-container input::placeholder {
    font-style: italic;
    color: #7186A4;
    opacity: 1;
}

#landing-banner-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#banner-logo-img {
    position: absolute;
    top: 25px;
    left: 18px;
    width: 100px;
    height: 50px;
    z-index: 2;
}