#pf-cards-container {
	width: 100%;
	padding: 32px 6% 80px;
	height: max-content;
	min-height: max-content;
	max-height: max-content;
	display: -moz-flex;
	display: -webkit-flex;
	display: -ms-flex;
	display: flex;
	-moz-justify-content: center;
	-webkit-justify-content: center;
	-ms-justify-content: center;
	justify-content: center;
	flex: 1;
}

#pf-cards-wrapper {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
	column-gap: 16px;
	row-gap: 16px;
	justify-content: center;
	max-width: 1400px;
}

#pf-cards-wrapper-title {
	grid-column: 1/-1;
}