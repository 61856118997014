/* 
    * Foundational styling 
    * Styling for the elements present in base.html.twig
*/

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: "Roboto", sans-serif;
   color: #011F3D;
}

body {
   margin: 0px;
   height: 100vh;
   width: 100vw;
   overflow-y: hidden;
   overflow-x: hidden;

   display: flex;
   flex-direction: column;
}

.dlt-icon-close:before {
   content: "🞫";
   padding-top: 1px;
}

.dlt-tags[data-allow-selection] .dlt-tags__item--selected::before {
   display: none;
}

.main-content {
   flex: 1;
   overflow-y: auto;
   overflow-x: hidden;
   display: flex;
   flex-direction: column;
   margin-top: 64px;
}

.dlt-links.standalone {
   text-decoration: none;
}

.dlt-links:focus {
   outline: var(--dlt-border-width-hover, 2px) var(--dlt-border-style-hover, solid) var(--dlt-cards-color-interactive-primary-default, var(--dlt-component-color-interactive-primary-default, var(--dlt-color-background-interactive-primary-default, #1776bf))) !important;
   outline-offset: 2px;
   text-decoration: underline !important;
}

.custom-footer {
   background-color: #F0F2F4;
   padding: 70px 10% 70px;
}

.footer-top {
   text-align: left;
}

.footer-logo {
   display: inline-block;
   width: 150px;
   height: 50px;
}

.footer-line {
   border: 0;
   height: 1px;
   background-color: #ccc;
   margin: 10px 0;
}

.footer-container {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   padding-top: 16px;
   padding-bottom: 12px;
}

.footer-container .resources {
   flex: 1;
   display: flex;
   flex-wrap: wrap;
   column-gap: 24px;
   min-width: 700px;
}

.footer-column {
   min-width: 200px;
   margin-bottom: 20px;
}

.footer-column.intro {
   max-width: 440px;
   flex: 1;
}

@media only screen and (max-width: 750px) {
   .footer-container {
      flex-direction: column;
   }

   .footer-container .resources {
      min-width: revert;
   }
}

.footer-column h3 {
   font-size: 16px;
   line-height: 20px;
   margin-bottom: 10px;
   color: #011F3D;
   font-weight: 650;
}

.footer-column a {
   display: block;
   color: #36597D;
   text-decoration: none;
   margin-bottom: 10px;
   width: max-content;
   padding: 4px;
}

.footer-column a:hover {
   text-decoration: underline;
}

.footer-column a:focus {
   outline: 2px solid #36597D;
   text-decoration: underline;
}

.footer-bottom {
   text-align: left;
   padding-top: 10px;
}

.footer-bottom p {
   font-size: 12px;
   color: #36597D;
}

.socials-container {
   display: flex;
   flex-wrap: wrap;
   width: 100%;
   row-gap: 12px;
}

.footer-social-media {
   min-width: max-content;
   text-align: right;
}

a.social-media-icon {
   text-decoration: none;
   margin: 0 20px 0px 0px;
   display: inline-flex;
   justify-content: center;
   align-items: center;
   padding: 2px;
   height: 32px;
   width: 32px;
}

a.social-media-icon:last-child {
   margin: 0px
}

a.social-media-icon:hover,
a.social-media-icon:focus {
   outline: var(--dlt-border-width-hover, 2px) solid var(--dlt-links-color-border, var(--dlt-component-color-interactive-primary-default, var(--dlt-color-background-interactive-primary-default, #1776bf)));
}

.footer-privacy {
   flex: 1;
   min-width: max-content;
   font-size: 12px;
   display: inline-flex;
   align-items: center;
   column-gap: 14px;
   white-space: nowrap;
}

.footer-privacy span,
.footer-privacy a {
   color: #36597D;
   /* Or any other color you prefer */
   text-decoration: none;
   padding: 2px;
}

.footer-privacy a:hover {
   text-decoration: underline;
}

.footer-privacy a:focus {
   outline: solid #36597D 2px;
   text-decoration: underline;
}

/* Responsive behavior */
/* @media (max-width: 768px) {
    .footer-container {
        justify-content: center;
    }
    
    .footer-column {
        flex-basis: 100%; 
        max-width: 100%; 
        text-align: center; 
    }
} */


.site-tour {
   display: flex;
   align-items: center;
}

.site-tour-btn {
   padding: 0px 12px;
   background-color: #F0F2F4;
   border-width: 2px;
   font-weight: bold;
}

.dlt-button-secondary.site-tour-btn:focus {
   padding: 0px 12px;
   background-color: #c5edea;
   border-width: 2px;
   font-weight: bold;
}

.dlt-button-secondary.site-tour-btn:hover:not(:disabled) {
   padding: 0px 12px;
   background-color: #c5edea;
   border-width: 2px;
   font-weight: bold;
}

.site-tour-btn svg {
   width: 24px;
   height: 24px;
   margin-right: 8px;
}

.site-tour-icon {
   margin-right: 7px;
   display: flex;
   align-items: center;
}

.site-tour-icon svg {
   fill: #058192;
   width: 26px;
   height: 26px;
}