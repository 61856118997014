/* -------- Scrollbar overwrite styling ------- */
.mCSB_inside#mCSB_1>.mCSB_container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    top: 0px !important;
}
/* -------- Scrollbar overwrite styling ------- */
/*.mCustomScrollBox 
{
    overflow: auto !important;
    touch-action: pan-y !important;
}*/

#mCSB_1 .mCSB_dragger_bar,
#mCSB_1 .mCSB_draggerRail {
    margin-right: 0px !important;
}

#mCSB_1 .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #8d9ca8;
    width: 8px;
    border-radius: 4px;
}

#mCSB_1 .mCSB_scrollTools .mCSB_draggerRail {
    background-color: #eceded;
    width: 8px;
}

.search-filter-container .dlt-accordion-arrow {
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
}

.search-filter-container .dlt-checkbox input[type="checkbox"]:indeterminate:hover+label::after {
    background-color: transparent;
}

/* -------------- Search styling -------------- */
.search-wrapper {
    min-height: 100vh;
    height: max-content;
    /* padding: 40px min(40px, 20%); */
    min-height: max-content;
}

.search-content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-flex-direction: row;
    -moz-box-flex-direction: row;
    -ms-flex-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;

    column-gap: 16px;
    position: relative;
}

/* Search filter styling */
.search-filter-container {
    width: 360px;
    min-width: 360px;
    max-width: 360px;
    background-color: #f9fafb;
    border-right: solid #d2d9e0 1px;
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 2;
}

.responsive-filter-topbar {
    display: none;
}

.search-filter {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.filter-accordion-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.filter-category .title {
    padding: 16px 0px 2px 18px;
}

#product-filter {
    padding: 28px 0px 8px 10px;
    flex: 1;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

#product-search-count {
    padding: 6px 0px 0px;
    font-size: 12px;
    color: #5c6c7b;
    width: 100%;
    text-align: left;
}

#product-filter-search-container {
    margin: 8px 38px 16px 18px;
    width: auto;
    flex: none;
}

.search-filter-container fieldset {
    border: none;
}

/* .search-filter-container .dlt-accordion .product:hover, */
.search-filter-container .dlt-accordion .dlt-accordion-title {
    border-bottom: none;
    border-top: none;
    box-shadow: none;
    align-items: start;
    height: max-content;
    padding: 6px 12px 6px;
    background-color: #f9fafb;
    justify-content: space-between;
}

.search-filter-container .dlt-accordion .dlt-accordion-title:hover {
    background-color: #f0f2f4;
    color: #1c5569;
    outline: transparent;
}

/* .search-filter-container .dlt-accordion .dlt-accordion-title:hover .title {
    color: #1c5569;
} */

.search-filter-container .dlt-accordion .dlt-accordion-title:focus {
    outline: solid 2px #058192;
}

.search-filter-container .dlt-accordion .dlt-accordion-title:active {
    outline: none;
    background-color: #c5edea;
}

.search-filter-container .dlt-accordion,
.search-filter-container .dlt-accordion-item {
    border: none !important;
    background-color: transparent;
}

#product-filter .filter-category-item {
    height: 100%;
}

#filter-product-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.search-filter-container .dlt-accordion-item.dlt-accordion-content-active {
    border-bottom: var(--dlt-border-width-default, 1px) var(--dlt-border-style-default, solid) var(--dlt-accordion-color-border-separator, var(--dlt-component-color-border-separator, var(--dlt-color-border-static-primary, #e5e5e5)));
    border-top: var(--dlt-border-width-default, 1px) var(--dlt-border-style-default, solid) var(--dlt-accordion-color-border-separator, var(--dlt-component-color-border-separator, var(--dlt-color-border-static-primary, #e5e5e5)));
}

.search-filter-container .dlt-accordion .dlt-accordion-item.dlt-accordion-content-active .dlt-accordion-title {
    border-bottom: none;
}

#product-filter-list {
    padding-left: 4px;
    flex: 1;
    /* overflow-y: auto;
    overflow-x: hidden; */
    overflow: auto;
    /*scrollbar-color: #dbe1e6 #F5F6F8;*/
}

#product-filter-list::-webkit-scrollbar {
    width: 8px;
    height: 4px;
}

#product-filter-list .dlt-accordion-title {
    border-bottom: none;
    border-top: none;

    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
}

.search-filter-container .dlt-accordion .dlt-accordion-item .dlt-accordion-arrow,
.search-filter-container .dlt-accordion .dlt-accordion-item.dlt-accordion-content-active .dlt-accordion-arrow {
    margin: 0px;
    margin-top: 3px;
}

.search-filter-container .dlt-accordion .dlt-accordion-item .dlt-accordion-title:hover .dlt-accordion-arrow,
.search-filter-container .dlt-accordion .dlt-accordion-item.dlt-accordion-content-active .dlt-accordion-title:hover .dlt-accordion-arrow {
    margin-top: 3px;
    border: none !important;
}

.search-filter-container .dlt-accordion .dlt-accordion-item .dlt-accordion-content {
    padding: 0px 16px;
}

.search-filter-container .dlt-accordion .dlt-accordion-item.dlt-accordion-content-active .dlt-accordion-content {
    background-color: #f9fafb;
    padding: 6px 16px 2px;
}

.search-filter-container .filter-accordion-content {
    padding: 2px 16px;
}

.search-filter-container .dlt-checkbox .filter-checkbox+label {
    font-size: 14px;
    line-height: 22px;
}

.search-filter-container .dlt-checkbox .filter-checkbox+label::before {
    top: 2px;
    line-height: 16px;
}

.search-filter-container .dlt-checkbox .filter-checkbox+label::after {
    line-height: 18px;
}

.dlt-checkbox input[type=checkbox]:indeterminate+label:after {
    top: -5px;
}

.search-filter-container .dlt-checkbox .filter-checkbox.version-checkbox+label {
    font-size: 13px;
}

/* ---------- Search filter buttons styling ---------- */
#search-filter-activity {
    height: 64px;
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

#search-filter-activity .dlt-filter__panel-actions {
    margin: 0px;
    flex: 0;
}

#resetFilterBtn,
#applyFilterBtn {
    transition-duration: 150ms;
}

#resetFilterBtn {
    margin-right: 12px;
}

#search-filter-collapse-btn {
    border: none;
    background-color: transparent;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
}

#search-filter-collapse-btn:hover {
    cursor: pointer;
    outline: solid 2px #058192;
    outline-offset: -2px;
}

.search-wrapper .collapse-item-wrapper {
    align-items: center;
    background-color: transparent;
    bottom: 0;
    display: flex;
    justify-content: flex-start;
    position: sticky;
    right: 0;
    width: 100%;
    top: max(154px, calc(100vh - 154px));
    height: max-content;
}

.search-wrapper .collapse-item-wrapper:not(.visible) {
    display: none;
}

.collapse-item-wrapper .dlt-button-icon {
    border: 0;
    border-radius: 0 16px 16px 0;
    box-shadow: var(--dlt-elevation-border, 0 0 0 transparent), var(--dlt-elevation-md, 0 2px 12px 2px rgba(0, 0, 0, .12));
    height: 56px;
    margin-left: 0;
    margin-right: 8px;
    outline: 0;
    width: 32px;
    background-color: #f9fafb;
}

#sm-filter-expand-btn {
    background-color: white;
    width: max-content;
    border: solid #36597D 1px;
    color: #5E7A97;
    font-weight: 500;

    font-size: 13px;
    cursor: pointer;

    display: none;
}

#sm-filter-expand-btn:hover {
    outline: solid #36597D 2px;
    outline-offset: -2px;
}

#sm-filter-expand-btn:active {
    background-color: #36597D !important;
    color: white !important;
    border: solid #36597D 1px !important;
}

/* ------------- search checkboxes styling ------------- */
.product-filter-item {
    height: 100%;
    background-color: transparent;
    padding: 2px 12px;
    /*overflow: hidden;*/
    display: none;
    cursor: pointer;
}

.product-filter-item[search-valid="true"] {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}

.product-filter-title {
    min-height: 44px;
    width: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    justify-content: center;
    text-align: left;
    cursor: pointer;
}

.product-filter-title:hover {
    background-color: #f0f2f4;
    color: #1c5569;
    outline: transparent;
    outline-offset: -2px;
}

.product-filter-title:focus {
    outline: solid 2px #058192;
}

.product-filter-title:active {
    outline: none;
    background-color: #c5edea;
}

.product-filter-item .product-filter-title i {
    font-size: 24px;
    transform: rotateZ(90deg);
    height: 24px;
    width: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all 200ms ease-in-out;
}

.product-checkbox-div,
.dlt-checkbox.product-checkbox-div {
    padding-bottom: 0px;
    flex: 1;
}

.product-filter-panel {
    padding: 2px 4px 2px 12px;
    margin: 4px 0px 10px 18px;
    border-left: solid #D2D9E0 2px;
}

.product-filter-title[aria-expanded="false"]~.product-filter-panel {
    height: 0px !important;
    /*overflow: hidden;*/
    margin-top: 0px;
    margin-bottom: 0px;
}

.version-checkbox-div {
    padding: 0px;
    padding-top: 9px;
    margin-left: 4px;
    text-align: left;
}

/* Language Checkboxes */
#filter-language-checkboxlist {
    padding-left: 28px;
}

#filter-language-checkboxlist .dlt-checkbox label {
    font-size: 13px;
}

/* ------------- Search results container ------------- */
.search-results-container {
    padding: 1.5rem 2rem;
    -webkit-box-flex: 1;
    width: 100%;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    /* margin-left: 320px; */
}

.gsc-control-cse {
    padding: 0px;
    height: 100%;
    overflow: hidden;
}

.gsc-control-wrapper-cse {
    width: 100%;
    height: 100%;
}

.gsc-above-wrapper-area {
    border-bottom: 0px !important;
}

/* Results info text styling */
.gsc-result-info {
    color: #011f3d !important;
    font-size: 18px !important;
    line-height: 32px !important;
    font-weight: 500 !important;
    padding: 0px 0px 8px 0px !important;
    margin-left: -2px;
}

/* Quickgilter tags styling */
.filter-tags-container,
.dlt-tags.filter-tags-container {
    display: -webkit-flex;
    display: flex;
    justify-content: left;
    padding-left: 12px;
    margin-top: 24px;
    flex-wrap: wrap;
    column-gap: 6px;
}

.filter-tags-container:not([tag-count="0"]) {
    border-bottom: solid #E5E9ED 1px;
    padding-bottom: 16px;
}

.filter-tags-container.collapsed:not([tag-count="0"]) {
    padding-bottom: 0px;
}

.filter-tags-container .filter-tags-container-title {
    display: none;
}

.filter-tags-container:not([tag-count="0"]) .filter-tags-container-title {
    display: block !important;
    font-weight: 500;
    flex-basis: 50%;
    margin-bottom: 6px;
}

.filter-tags-container .filter-tags-container-title #filter-tags-count {
    margin-left: 4px;
    color: #058192;
}

.filter-tags-container:not(.collapsed) .filter-tags-container-title #filter-tags-count {
    display: none;
}

.filter-tags-container[tag-count="0"],
.dlt-tags.filter-tags-container[tag-count="0"] {
    margin-top: 0px;
}

.filter-tags-container .filter-tag-group {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    column-gap: 6px;
    flex-basis: 100%;
}

.filter-tags-container.collapsed .filter-tag-group {
    display: none !important;
}

.filter-tags-container .filter-tag-group::before {
    content: attr(parent-name)':';
    display: inline-block;
    min-height: 20px;
    height: min-content;
    margin-right: 4px;
    margin-top: 2px;
    margin-bottom: 6px;
    font-weight: 400;
}

.filter-tags-container.dlt-tags .dlt-tags__item {
    margin-right: 0px;
    margin-left: 0px;
}

.filter-tags-container.dlt-tags .dlt-tags__item span {
    max-width: 220px;
}

.filter-tags-container .filter-tag-item:hover {
    cursor: pointer;
    background-color: #D2D9E0;
}

.filter-tags-container .filter-tag-item:active {
    cursor: pointer;
    background-color: #D2D9E0;
    outline: solid #058192 2px;
    outline-offset: -2px;
}

.filter-tags-container .filter-tag-item .category {
    font-weight: bold;
}

.filter-tags-container .filter-tags-clear {
    display: inline-block;
    flex-basis: 100%;
    margin-top: 6px;
}

.filter-tags-container.collapsed .filter-tags-clear {
    display: none !important;
}


.filter-tags-container .collapse-quickfilter-container {
    flex-basis: calc(50% - 6px);
    margin-bottom: 6px;
}

.filter-tags-container.collapsed .collapse-quickfilter-container i {
    transform: rotateZ(180deg);
}

.filter-tags-container[tag-count="0"] .collapse-quickfilter-container {
    display: none;
}

.filter-tags-container .dlt-button-icon {
    float: right;
    margin-top: -8px;
}

.filter-tags-container[tag-count="0"] .filter-tags-clear,
.dlt-tags.filter-tags-container[tag-count="0"] .filter-tags-clear {
    display: none;
}

.dlt-button-elt {
    --dlt-buttons-line-height: calc(var(--dlt-sizing-md) - 2px);
    --dlt-buttons-line-height-sm: calc(var(--dlt-sizing-sm) - 2px);
    --dlt-buttons-line-height-lg: calc(var(--dlt-sizing-lg) - 2px);
    --dlt-buttons-accent-line-height: calc(var(--dlt-sizing-md) - 4px);
    --dlt-buttons-accent-line-height-sm: calc(var(--dlt-sizing-sm) - 4px);
    --dlt-buttons-accent-line-height-lg: calc(var(--dlt-sizing-lg) - 4px);

    align-items: center;
    border: var(--dlt-border-width-default, 1px) var(--dlt-border-style-default, solid) transparent;
    border-radius: var(--dlt-border-radii-default, 0);
    color: var(--dlt-buttons-color-text-primary, var(--dlt-component-color-text-primary, var(--dlt-color-text-interactive-primary, #1776bf)));
    cursor: pointer;
    display: inline-flex;
    font-family: var(--dlt-font-family-base, "Roboto", "Helvetica", Arial, sans-serif);
    font-size: var(--dlt-font-size-xs, .875rem);
    font-weight: var(--dlt-font-weight-regular, 400);
    line-height: var(--dlt-buttons-line-height, 30px);
    margin: 0;
    outline: var(--dlt-border-width-hover, 2px) var(--dlt-border-style-hover, solid) transparent;
    outline-offset: -2px;
    padding: 0 var(--dlt-spacing-1-x-half, .75rem);
    text-decoration: none;
    -moz-transition: var(--dlt-time-transition-short, .25s) ease-in;
    -o-transition: var(--dlt-time-transition-short, .25s) ease-in;
    -webkit-transition: var(--dlt-time-transition-short, .25s) ease-in;
    transition: var(--dlt-time-transition-short, .25s) ease-in;
}

.gsc-result-info {
    font-weight: 700 !important;
}

/* Search result item styling */
.gs-result {
    font-family: 'Roboto', sans-serif;
}

.result-item {
    padding: 18px 0px 2px;
    border-bottom: solid 1px #D9DCE1;
}

.result-item:first-child {
    padding-top: 0px;
}

.result-title-anchor,
.result-title-anchor:visited {
    font-size: 18px;
    font-weight: 700;
    color: #058192;
}

.result-url {
    font-size: 14px;
    font-style: italic;
    color: #7E96AE;
    word-break: break-all;
}

.result-heirarchy {
    display: block;
    font-size: 14px;
    font-weight: 600;
}

.result-description {
    color: var(--dlt-color-text-static-primary, #011F3D);
    font-size: 14px;
    font-weight: var(--dlt-font-weight-regular, 400);
    line-height: 22px;
    margin: 0 0 16px;
}

.result-item b {
    color: revert;
    background-color: #F2F8B3;
    padding: 0px 3px;
    font-weight: 600 !important;
}

.result-item .result-description b {
    font-weight: normal;
}

/* Additional Styling */

.gcsc-more-maybe-branding-root {
    display: none !important;
}

/* Hiding the search results sorting dropdown */
.gsc-orderby-container {
    display: none !important;
}

.gsc-selected-option-container.gsc-inline-block {
    border: solid 1px #011f3d;
    background-color: white;
    border-radius: 0px;
    box-shadow: none;
    padding: 0px 34px 0 6px;
}

.gsc-selected-option {
    position: relative;
    width: 100%;
    font-weight: 400;
    color: #011f3d;
    font-size: 12px;
    cursor: pointer;
}

.gsc-option-menu {
    top: 27px !important;
}

.gsc-option-menu-item {
    color: #011f3d;
    padding: 0px 12px;
    font-size: 12px;
    line-height: 14px;
    height: max-content;
}

.gsc-option-menu-item:hover {
    outline: solid 2px #058192;
    outline-offset: -2px;
}

.gsc-option-menu-item-highlighted {
    background-color: #ebf9f8 !important;
}

.gsc-selected-option-container.gsc-inline-block:focus {
    outline: solid 2px #058192;
    outline-offset: -2px;
}

.search-wrapper .gsc-orderby-label {
    color: #011f3d;
    font-weight: bold;
}

.search-wrapper .gsc-control-cse .gsc-option-selector {
    background: var(--arrow-down);
    top: 9px;
    height: 16px;
    width: 18px;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    right: 0px;
    cursor: pointer;
}

.gsc-above-wrapper-area-container tbody>tr {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* pagination */

/* Pagination Styling */
.search-results-container.gsc-cursor-box.gs-bidi-start-align {
    /* -webkit-box-shadow: rgb(210, 217, 224) 0px -1px 0px 0px inset, rgb(210, 217, 224) 0px 1px 0px 0px inset; */
    /* box-shadow: rgb(210, 217, 224) 0px -1px 0px 0px inset, rgb(210, 217, 224) 0px 1px 0px 0px inset; */
    margin: 0px;
    padding: 10px;
}

.search-results-container.gsc-results .gsc-cursor-box {
    border: none !important;
    border-top: solid rgb(210, 217, 224) 1px !important;
}

.search-results-container.gsc-results .gsc-cursor {
    color: #36597d;
    font-size: 14px;
    line-height: 28px;

    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 6px;
}

.search-results-container.gsc-results .gsc-cursor-box .gsc-cursor-page {
    color: #36597d;
    border-color: #36597d;
}

.gcsc-find-more-on-google-root {
    display: none !important;
}

.gsc-cursor-page {
    padding-left: 10px;
}

/* Pagination styling */
.gsc-cursor-page.pc-styling {
    display: none !important;
}

.gsc-cursor-box.pc-styling {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.gsc-cursor-box.pc-styling .gsc-cursor-container-previous,
.gsc-cursor-box.pc-styling .gsc-cursor-container-next {
    height: 24px;
    width: 24px;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    justify-content: center;
    align-items: center;
    margin-top: -1px;
}

.gsc-cursor-box.pc-styling .gsc-cursor-container-previous i,
.gsc-cursor-box.pc-styling .gsc-cursor-container-next i {
    font-size: 32px;
    height: 24px;
    width: 24px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #011F3D;
}

.gsc-cursor-box.pc-styling .gsc-cursor-container-previous:hover,
.gsc-cursor-box.pc-styling .gsc-cursor-container-next:hover {
    cursor: pointer;
    outline: solid #058192 2px;
    outline-offset: -2px;
    color: #011F3D;
}

.gsc-cursor-box.pc-styling .gsc-cursor-container-previous:active,
.gsc-cursor-box.pc-styling .gsc-cursor-container-next:active {
    cursor: pointer;
    color: #011F3D;
    background-color: #c5edea;
}

.results .gsc-results .gsc-cursor-box .gsc-cursor.pc-styling {
    width: min-content;
    display: inline-block;
}

.results .gsc-results .gsc-cursor-box .gsc-cursor-page.pc-styling {
    display: none;
}

.gsc-cursor-box .gsc-cursor-page.gsc-cursor-current-page::before {
    content: 'Page';
    font-weight: 400;
    padding-right: 4px;
    font-size: 16px;
}

.results .gsc-results .gsc-cursor-box .gsc-cursor-page.gsc-cursor-current-page {
    color: #011f3d;
    font-size: 16px;
}

.gsc-cursor-box.pc-styling .gsc-cursor-page.gsc-cursor-current-page {
    color: #011f3d;
    margin: 0px 14px;
    font-weight: 400;
    cursor: default;
    padding-left: 0px;
    font-size: 16px;
}

.gsc-cursor-box.pc-styling .gsc-cursor-page.gsc-cursor-current-page:hover {
    text-decoration: none;
}

/* Modifying search styling for mobile devices */
#gcse-searchbox .gsc-input-box {
    padding: 0px;
}

.gsc-webResult .gsc-result {
    border-bottom: none !important;
}

.gsc-results .gsc-cursor-box {
    border: none !important;
}

/* -------- Responsive styling -------- */

@media only screen and (max-width: 779px) {
    .search-filter-container {
        position: fixed;
        z-index: 999;
        top: 0px;
        height: 100%;
        left: -360px;
        margin-left: -360px;
        width: 300px;
        max-width: 300px;
        min-width: 300px;
    }

    .responsive-filter-topbar {
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    #responsive-logo {
        display: block;
        height: 64px;
        padding: 16px;
    }

    #sm-search-filter-collapse-btn {
        margin: 16px 16px 0px 0px;
    }

    #product-filter {
        padding-top: 0px;
    }

    .search-wrapper .collapse-item-wrapper {
        display: none !important;
    }

    #sm-filter-expand-btn {
        margin-right: 8px;
        display: inline-flex;
    }

    #search-filter-collapse-btn {
        display: none;
    }

    #search-filter-activity {
        justify-content: right;
    }
}

/* Hiding Ads */
.gsc-adBlock {
    display: none !important;
}